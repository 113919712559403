<template>
  <div>
    <!-- Modal para editar.. -->
    <v-dialog v-model="dialogEdit" width="500">
      <v-card>
        <v-toolbar color="primary" class="white--text" flat>
          <v-toolbar-title> Editar Broker externo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialogEdit = false" icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- ExternalBrokerEdit -->
        <v-card-text>
          <external-broker-edit
            typeComponent="ExternalBroker"
            :getItem="setItem"
            @setValidate="value => (valid = value)"
            @setData="value => (data = value)"
            @setCurrentScreen="value => (currentScreen = value)"
            @closeModal="value => (dialogEdit = value)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <createBrexComponent />
    </v-card-title>
    <v-data-table
      hide-default-header
      :headers="headers"
      :items="brexList"
      :search="search"
      :footer-props="{ itemsPerPageText: 'Brokers Externos por página' }"
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th
              v-for="h in headers"
              :key="h.value"
              class="grey darken-4 grey--text text--lighten-2"
            >
              <span>{{ h.text }}</span>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          fab
          elevation="0"
          small
          color="blue lighten-5"
          @click="editItem(item)"
        >
          <v-icon color="cyan darken-4">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// const mockBrex = {
//   _id: "64ac47fc1b075f0035e3a7ca",
//   real_estate_develop_id: [],
//   player_id: "0",
//   exam_passed: [],
//   status: 1,
//   phone: "+529952124578",
//   type: "external",
//   created_at: "2023-07-10T18:03:40.097Z",
//   updated_at: "2023-07-10T18:03:40.097Z",
//   real_estate_group_id: "5e9a2df024535649bdc51f54",
//   name: "Tulum form test",
//   email: "tulum@gardensc28.com",
//   raw_opportunities_array: [
//     {
//       raw_opportunity_id: "64ac47fc1b075f0035e3a7c7",
//       observations:
//         "Mensaje: Prueba del formulario de Tulum Gardens.\tEnviado desde el desarrollo de Tulum Gardens. Del sitio: costarealtygroupmexico.com",
//       source_id: "6165b37aedfca25de9ea9dd3",
//       source_name: "Página Web",
//       budget: 0,
//       registered: false
//     }
//   ]
// };
// const moocBrexList = [mockBrex, mockBrex];

export default {
  components: {
    ExternalBrokerEdit: () =>
      import("@/components/external-broker/ExternalBrokerEdit.vue"),
    createBrexComponent: () => import("./components/createBrexComponent.vue")
  },
  name: "ExternalBrokerList",
  created() {
    this.fetchBrexList();
  },
  data() {
    return {
      valid: false,
      currentScreen: false,
      data: null,

      dialog: false,
      dialogEdit: false,
      loadingSave: false,

      search: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Teléfono", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Inmobiliaria", value: "real_estate_name" },
        { text: "", value: "actions", sortable: false }
      ],
      setItem: null
    };
  },
  computed: {
    ...mapState({
      brexList: state => state.brex.brexList
    })
  },
  methods: {
    ...mapActions({
      fetchBrexList: "brex/fetchBrexList"
    }),
    /**
     * cuenta las opportunidades registradas o no del broker externo
     * basandose en el campo registered de raw_opportunities_array
     * @param {String} id - Id del Lead
     * @param {boolean} register - Indica si contar los registrado(true) o no registrados(false)
     * @returns {Number} Total
     */
    countOpportunities(id, register) {
      const index = this.brexList.findIndex(brex => brex._id == id);
      const opportunities = this.brexList[index].raw_opportunities_array || [];
      return opportunities.filter(opp => opp.registered == register).length;
    },
    editItem(item) {
      this.dialogEdit = true;
      this.setItem = item;
    }
  }
};
</script>
